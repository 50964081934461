import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './ui/Navbar/Navbar';
import Home from './pages/Home/Home';
import Partners from './pages/Partners/Partners';
import Studios from './pages/Studios/Studios';
import Artists from './pages/Artists/Artists';
import ScrollToTop from './common/ScrollToTop/ScrollToTop';
import Services from './common/Services/Services';
import Communities from './pages/Communities/Communities';
import Contact from './pages/Contact/Contact';
import Footer from './ui/Footer/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<><Navbar /> <Home /> <Footer /></>} />
          <Route path="/equipe" element={<><Navbar /> <Partners /> <Footer /></>} />
          <Route path="/estudios" element={<><Navbar /> <Studios /> <Footer /></>} />
          <Route path="/artistas" element={<><Navbar /> <Artists /> <Footer /></>} />
          <Route path="/servicos" element={<><Navbar /> <Services /> <Footer /></>} />
          <Route path="/comunidade" element={<><Navbar /> <Communities /> <Footer /></>} />
          <Route path="/contato" element={<><Navbar /> <Contact /> <Footer /></>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;