import React from 'react'
import './StudioHome.scss'
import bg from '../../../assets/images/partners/bg-section.jpg'
import image1 from '../../../assets/images/studio/1/ONE-IMAGE-1.jpg'
import image2 from '../../../assets/images/studio/2/TWO-IMAGE-1.jpg'
import image3 from '../../../assets/images/studio/3/THREE-IMAGE-1.jpg' 
import image4 from '../../../assets/images/studio/3/MIDAS.jpg' 
import { Link } from 'react-router-dom'

const StudioHome = () => {
  return (
    <section className='StudioHome'>
      <div className="background-studio" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <div className="title">
            <h1>Conheça nossos estúdios</h1>
            <span>MIDAS MUSIC</span>
          </div>
          <p>Fale com a gente e marque uma visita.</p>
        </div>
      </div>
      <div className="container-cards">
        <div className="content">
          <div className="card-studio">
            <div className="background-card" style={{ backgroundImage: `url(${image1})` }}></div>
            <p>Estúdio 1</p>
            <button onClick={() => window.open('https://api.whatsapp.com/send?phone=5511994405225&text=Mande%20sua%20mensagem.', '_blank')}>AGENDAR</button>
          </div>

          <div className="card-studio">
            <div className="background-card" style={{ backgroundImage: `url(${image2})` }}></div> 
            <p>Estúdio 1</p>
            <button onClick={() => window.open('https://api.whatsapp.com/send?phone=5511994405225&text=Mande%20sua%20mensagem.', '_blank')}>AGENDAR</button>
          </div>

          <div className="card-studio">
            <div className="background-card" style={{ backgroundImage: `url(${image3})` }}></div>
            <p>Estúdio 1</p>
            <button onClick={() => window.open('https://api.whatsapp.com/send?phone=5511994405225&text=Mande%20sua%20mensagem.', '_blank')}>AGENDAR</button>
          </div>

          <div className="card-studio">
            <div className="background-card" style={{ backgroundImage: `url(${image4})` }}></div>
            <p>Estúdio 1</p>
            <button onClick={() => window.open('https://api.whatsapp.com/send?phone=5511994405225&text=Mande%20sua%20mensagem.', '_blank')}>AGENDAR</button>
          </div>
        </div>
        <Link to="/estudios">Ver todos os estúdios</Link>
      </div>
    </section>
  )
}

export default StudioHome
