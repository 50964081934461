import React from 'react'
import './Services.scss'
import bg from '../../../assets/images/services/bg.jpg'
import microphone from '../../../assets/images/home/market/microphone.png'
import djdeck from '../../../assets/images/home/market/dj_deck.png'
import audiointerface from '../../../assets/images/home/market/audio_interface.png'
import drummachine from '../../../assets/images/home/market/drum_machine.png'
import synthesizer from '../../../assets/images/home/market/synthesizer.png'
import djmixer from '../../../assets/images/home/market/dj_mixer.png'
import vinyl from '../../../assets/images/home/market/vinyl.png'
import speaker from '../../../assets/images/home/market/speaker.png'
import headphones from '../../../assets/images/home/market/headphones.png'  

const Services = () => {
  return (
    <div className='Services'>
      <div className="background-services" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <div className="title">
            <h1>Serviços</h1>
            <span>SERVIÇOS</span>
          </div>
          <p>Mais de 30 anos de experiência no mercado fonográfico</p>
        </div>
      </div>

      <div className="container-markets">
        <div className="container-line">
          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={microphone} alt="Microfone" />
            </div>
            <div className="market-body">
              <h3>PRODUÇÃO MUSICAL</h3>
              <p>Produza suas músicas com qualidade internacional e uma equipe vencedora de diversos prêmios Grammys e recordista de vendagens e plays nas plataformas digitais.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={djdeck} alt="mixagem" />
            </div>
            <div className="market-body">
              <h3>MIXAGEM</h3>
              <p>Nossos estúdios estão equipados com os lendários consoles solid state logic e conversores de alta resolução, além de outboards analógicos e plugins para a sua mixagem.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={audiointerface} alt="MASTERTIZAÇÃO" />
            </div>
            <div className="market-body">
              <h3>MASTERTIZAÇÃO</h3>
              <p>A experiência dos nossos engenheiros e os equipamentos analógicos e digitais vintage ou de última geração estão à sua disposição para finalizar a sua música com o punch e a clareza conhecidos e ouvidos em muitos sucessos que fazem parte da história da música brasileira por várias gerações.</p>
            </div>
          </div>
        </div>

        <div className="container-line">
          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={djmixer} alt="Mixagem em Dolby Atmos" />
            </div>
            <div className="market-body">
              <h3>Mixagem em Dolby Atmos</h3>
              <p>Com um estúdio certificado pela Dolby, mixamos sua música no formato 7.1.4 entregando uma experiência 100% imersiva.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={vinyl} alt="Vinyl" />
            </div>
            <div className="market-body">
              <h3>Consultoria Individual</h3>
              <p>A equipe Midas irá ajudar você a definir o seu conceito artístico, repertório e os caminhos do marketing musical. Você estará em contato com os profissionais da Midas para tirar suas dúvidas e assim estar pronto para o mercado.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={speaker} alt="JINGLES" />
            </div>
            <div className="market-body">
              <h3>JINGLES</h3>
              <p>Dê voz à sua marca. Composições curtas e originais para vender seu serviço ou produto.</p>
            </div>
          </div>
        </div>

        <div className="container-line">
          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={drummachine} alt="GERENCIAMENTO ARTÍSTICO" />
            </div>
            <div className="market-body">
              <h3>GERENCIAMENTO ARTÍSTICO</h3>
              <p>Assessoria e acompanhamento da equipe Midas Music para ajudar você a dar os próximos passos da sua carreira com direção artística assinada por Rick Bonadio.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={headphones} alt="WORKSHOPS" />
            </div>
            <div className="market-body">
              <h3>WORKSHOPS</h3>
              <p>Participe dos nossos workshops com nossa equipe. Fique ligado na agenda, teremos workshops de carreira, marketing, composição e gravação</p>
            </div>
          </div>
        </div>

        <div className="button-market">
          <a href="https://api.whatsapp.com/send?phone=5511994405225&amp;text=Mande%20sua%20mensagem." target="_blank" role="button" rel="noreferrer">
            <span>
              <span>FALE COM A GENTE</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Services
