import React, { useState } from 'react';
import './ImageItem.scss'; // Importar o arquivo de estilos

const ImageItem = ({ thumbnail, fullsize, alt }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="image-item">
            <img
                loading="lazy"
                src={thumbnail}
                alt={alt}
                onClick={openModal}
                className="thumbnail"
            />

            {isModalOpen && (
                <div className="modal" onClick={closeModal}>
                    <span className="close">&times;</span>
                    <img loading="lazy" className="modal-content" src={fullsize} alt={alt} />
                </div>
            )}
        </div>
    );
};

export default ImageItem;
