import React from 'react'
import './ArtistsHome.scss'
import vitor from '../../../assets/images/artists/vitor.jpg'
import titas from '../../../assets/images/artists/titas.jpg'
import zimbra from '../../../assets/images/artists/zimbra.jpg'
import ArtistCard from '../ArtistCard/ArtistCard'
import { Link, unstable_HistoryRouter, useNavigate } from 'react-router-dom'

const ArtistsHome = () => {
  const navigate = useNavigate();
  const artists = [
    {
      name: 'Titãs',
      backgroundImage: titas,
      socialLinks: {
        facebook: 'https://www.facebook.com/titasoficial',
        twitter: 'https://twitter.com/titasoficial',
        instagram: 'https://www.instagram.com/titasoficial/?hl=en',
      },
    },
    {
      name: 'Vitor Kley',
      backgroundImage: vitor,
      socialLinks: {
        facebook: 'https://www.facebook.com/vitorkleyoficial',
        twitter: 'https://twitter.com/VitorKley',
        instagram: 'https://www.instagram.com/vitorkley/',
      },
    },
    {
      name: 'Zimbra',
      backgroundImage: zimbra,
      socialLinks: {
        facebook: 'https://www.facebook.com/bandazimbra',
        twitter: 'https://twitter.com/zimbraoficial',
        instagram: 'https://www.instagram.com/bandazimbra/',
      },
    },
  ]
  return (
    <div className='ArtistsHome'>
      <div className="container-title">
        <div className="widget">
          <h1>Conheça nossos artistas</h1>
          <span>ARTISTAS</span>
        </div>
        <div className="container-artists">
          <div className="box-artists">
            {artists.map((artist) => (
              <ArtistCard name={artist.name} backgroundImage={artist.backgroundImage} socialLinks={artist.socialLinks} />
            ))}
          </div>
          <Link to="/artistas">
            <button className="btn-artists">
              Ver todos os artistas
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArtistsHome
