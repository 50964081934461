import React from 'react'
import bg from '../../../assets/images/communities/bg.jpg'
import './Communities.scss'

const Communities = () => {
  return (
    <div className='Communities'>
      <div className="background-communities" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <div className="title">
            <h1>Comunidade</h1>
            <span>COMUNIDADE MIDAS</span>
          </div>
          <p>Fique por dentro das novidades da gravadora e do Midas Studios</p>
        </div>
      </div>

      <div className="container-contact">
        <div className="header-contact">
          <h2>Faça parte da Comunidade do Midas</h2>
          <p>Faça parte da comunidade Midas de artistas, músicos, produtores, compositores, engenheiros, estúdios e selos parceiros. Nossa intenção é reunir pessoas que amam a música de qualidade no Brasil e no exterior para que possamos colaborar, trocar informações e estarmos juntos em projetos e eventos futuros. Estamos abertos a sugestões e parcerias. </p>

          <div className="button-external">
            <a href="https://www.facebook.com/groups/884754389501935/" target="_blank" role="button" rel="noreferrer">
              <span>
                <span>PARTICIPE</span>
              </span>
            </a>
          </div>
        </div>

        <div className="header-contact">
          <h2>Qual Serviço da Midas Music é o Ideal pra você?</h2>
          <p>Muito obrigado pelo interesse em saber como podemos ajudar você a elevar sua carreira na música por meio dos nossos serviços e consultorias.
            Esse formulário foi feito para que possamos entender mais sobre sobre você e suas necessidades. Desse modo, nossos Consultores poderão entrar em contato e recomendar a nossa solução mais adequada para você alcançar seus objetivos.</p>
          <p>ATENÇÃO: Somente entraremos em contato se você responder todas as perguntas e finalizar o formulário
            corretamente. Toda informação colocada nesse formulário é tratada de maneira sigilosa e nunca será
            compartilhada</p>

        </div>
      </div>
    </div>
  )
}

export default Communities
