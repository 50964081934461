import React, { Suspense } from 'react'
import HeaderHome from '../../common/HeaderHome/HeaderHome'
import HomeMarket from '../../common/HomeMarket/HomeMarket'
import PortfolioGallery from '../../common/PortfolioGallery/PortfolioGallery'
import './Home.scss'
import StudioHome from '../../common/StudioHome/StudioHome'
import ArtistsHome from '../../common/ArtistsHome/ArtistsHome'
import LocationsHome from '../LocationsHome/LocationsHome'

const Home = () => {
  return (
    <>
      <Suspense fallback={<div className="skeleton-loader">Loading...</div>}>
        <HeaderHome />
      </Suspense>
      <HomeMarket />
      <Suspense fallback={<div className="skeleton-loader">Loading...</div>}>
        <div className="container-gallery">
          <PortfolioGallery />
        </div>
      </Suspense>
      <Suspense fallback={<div className="skeleton-loader">Loading...</div>}>
        <StudioHome />
      </Suspense>
      <Suspense fallback={<div className="skeleton-loader">Loading...</div>}>
        <ArtistsHome />
      </Suspense>
      <Suspense fallback={<div className="skeleton-loader">Loading...</div>}>
        <LocationsHome />
      </Suspense>
    </>
  )
}

export default Home
