import React, { useState } from 'react';
import './PortfolioGallery.scss';
import image1 from '../../../assets/images/home/gallery/IMAGE-1.jpeg';
import image2 from '../../../assets/images/home/gallery/IMAGE-2.jpeg';
import image3 from '../../../assets/images/home/gallery/IMAGE-3.jpeg';  
import image4 from '../../../assets/images/home/gallery/IMAGE-4.jpeg';
import image5 from '../../../assets/images/home/gallery/IMAGE-5.jpeg';

const PortfolioGallery = () => {
  const [hoveredIndex, setHoveredIndex] = useState(-1); 
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5
  ]

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  return (
    <section className="portfolio-gallery">
      <div className="row-items" style={{ backgroundImage: hoveredIndex === -1 ? `url(${images[0]})` : `url(${images[hoveredIndex]})` }}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`row-item ${hoveredIndex === index ? 'hovered' : ''}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="row-item-info">
              <p className="info-subtitle"></p>
              {/* <h3 className="info-title"></h3> */}
            </div>
          </div>
        ))}
      </div>
      <div className="gallery-items">
        {images.map((image, index) => (
          <>
            <div
              key={index}
              className={`image-item ${hoveredIndex === index ? 'current-item' : ''}`}
            ></div>
            <div
              key={index}
              className={`image-item ${hoveredIndex === index ? 'current-item' : ''}`}
              data-background={image}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </>
        ))}
      </div>
    </section>
  );
};

export default PortfolioGallery;
