import React from 'react';
import './HeaderHome.scss';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, EffectFade } from 'swiper/modules';
import slide1 from '../../../assets/images/home/header/slide-1.jpeg';
import slide2 from '../../../assets/images/home/header/slide-2.jpeg';
import slide3 from '../../../assets/images/home/header/slide-3.jpeg';
import slide4 from '../../../assets/images/home/header/slide-4.jpeg';

SwiperCore.use([Autoplay, EffectFade]);

const HeaderHome = () => {
  return (
    <section className="header-home">
      <div className="background-slideshow swiper-container">
        <Swiper
          className="swiper-wrapper"
          loop={true}
          autoplay={{ delay: 5000 }}
          effect="fade"
          fadeEffect={{ crossFade: true }}
        >
          <SwiperSlide className="background-slideshow__slide">
            <div className="background-slideshow__slide__image" style={{ backgroundImage: `url(${slide1})` }}></div>
          </SwiperSlide>
          <SwiperSlide className="background-slideshow__slide">
            <div className="background-slideshow__slide__image" style={{ backgroundImage: `url(${slide2})` }}></div>
          </SwiperSlide>
          <SwiperSlide className="background-slideshow__slide">
            <div className="background-slideshow__slide__image" style={{ backgroundImage: `url(${slide3})` }}></div>
          </SwiperSlide>
          <SwiperSlide className="background-slideshow__slide">
            <div className="background-slideshow__slide__image" style={{ backgroundImage: `url(${slide4})` }}></div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="background-overlay"></div>
      <div className="container column-gap-default">
        <div className="column top-column">
          <div className="widget-wrap">
            <div className="widget">
              <h1>Bem-Vindo ao Midas.</h1>
            </div>
            <div className="widget ">
              <span>MIDAS MUSIC</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeaderHome;
